import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Modal, Button } from "react-bootstrap"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/SEO/seo"

const HeaderSection = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  /* max-height: 100vh; */
  overflow-x: hidden;
  padding: 30px 0;
  position: relative;
  width: 100%;

  @media only screen and (min-width: 450px) {
    align-items: center;
  }

  .gatsby-image-wrapper {
    bottom: 0;
    left: 0;
    position: absolute !important;
    right: 0;
    top: 0;

    &:before {
      content: "";
      background: #000;
      bottom: 0;
      left: 0;
      opacity: 0.75;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }
`

const HeaderText = styled.div`
  color: #fff;
  margin: 0 auto;
  max-width: 640px;
  padding: 0 20px;
  position: relative;
  z-index: 2;

  h1 {
    font-family: georgia, serif;
    font-size: 30px;
    font-weight: 600;
    z-index: 2;

    @media only screen and (min-width: 450px) {
      font-size: 60px;
    }
  }

  p {
    font-size: 15px;
    font-weight: normal;
    line-height: 23px;
    margin-bottom: 20px;

    @media only screen and (min-width: 450px) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  ul {
    margin-bottom: 50px;
  }
`

const HeaderButtons = styled.div`
  display: flex;

  .button {
    background: #fff;
    border-radius: 30px;
    color: #000;
    font-weight: 500;
    margin-right: 20px;
    padding: 12px 25px;
    text-decoration: none;
  }
`

const FooterSection = styled.div`
  align-items: center;
  bottom: 0;
  color: #fff;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  left: 0;
  opacity: 0.5;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  z-index: 2;
`

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        headerImage: file(relativePath: { eq: "coverImage.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <Layout>
      <SEO />
      <HeaderSection>
        <HeaderText>
          <h1>Keld Trading LTD</h1>
          <p>
            Keld Trading Limited offers 20 years of trade execution experience
            specializing in "Futures and Options" execution services in all
            Exchange Traded Derivatives (ETDs)
          </p>
          <p>Exchanges covered include CME, CBOT, CBOE, ICE and EUREX.</p>

          <p>
            Markets covered include but are not limited to futures and options
            execution in:
          </p>

          <ul>
            <li>Short Term Interest Rates (STIRs)</li> <li>Bonds</li>
            <li>Equities</li> <li>Commodities</li> <li> Precious metals</li>
          </ul>
          <HeaderButtons>
            <button onClick={handleShow} className="button">
              Contact Us
            </button>
          </HeaderButtons>
        </HeaderText>
        <Img fluid={data.headerImage.childImageSharp.fluid} />
      </HeaderSection>
      <FooterSection>
        <div>© Keld Trading LTD</div>
        <div>Company # 10944152</div>
      </FooterSection>
      {/*{new Date().getFullYear()}*/}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Name: Neil Tesi</p>
          <p>
            Email:{" "}
            <a href="mailto:neil@keldtrading.com">neil@keldtrading.com</a>
          </p>
          <p>Number: 07967015883</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default IndexPage
